import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { use } from '../Helpers'
import { call } from '../Methods'
import Input from '../Admin/_Components/Input'
import ValidateInput from '../ValidationDefaults'

export default function ForgotPassword () {
  let navigate = useNavigate()
  const { state } = useLocation()
  const [loading, setLoading] = useState(false)

  const ValidateAs = {
    email: ['required', 'email']
  }

  async function SendPasswordResetRequest (e) {
    setLoading(true)
    e.preventDefault()
    let email = document.querySelector(
      '.forgot-password-form input[name="email"]'
    ).value

    let validationResults = []
    ;[...e.target.elements].forEach(input => {
      if (ValidateAs[input.name]) {
        let errorSpan = document.querySelector(
          `span.warning-message[for=${input.name}]`
        )

        let result = ValidateInput(
          input.name,
          ValidateAs[input.name],
          input.value
        )
        validationResults.push(result)

        if (!result.result) {
          errorSpan.classList.add('show')
          errorSpan.innerText = result?.message
        }
      }
    })

    let validationResult = validationResults.every(r => r.result === true)

    if (validationResult) {
      const _result = await call.user.forgotPassword({ email: email })
      console.log(_result)
      if (_result?.success) {
        alert('Success. Please, check your e-mail.')
      } else {
        alert(_result?.error?.message)
      }
    }
    setLoading(false)
  }

  return (
    <div className='page account-page'>
      <img className='svg-background' src='/Backgrounds/wave-haikei.svg' />
      <div className="page-content">
      <div className='account-forms'>
        <div className='form forgot-password-form form-container'>
          <div className="form-header">
            <h1 className="main">Forgot Password</h1>
          </div>
          <form
            className='form'
            noValidate
            method='post'
            onSubmit={e => (!loading ? SendPasswordResetRequest(e) : null)}
          >
            <Input
              label={'E-mail'}
              fieldName={'email'}
              type={'email'}
              validateAs={'email'}
              disabled={loading}
              required={true}
            />
            <div className='button-box'>
              <button
                className='button button-full-width'
                type='submit'
                disabled={loading}
              >
                {loading ? 'Please,wait...' : 'Send'}
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>

    </div>
  )
}
