import axios from 'axios'
import Variables from './global'
import { use } from './Helpers.js'
import { redirect } from 'react-router-dom'

const header = () => {
  if (!use.token()) {
    redirect('/login')
  }
  return {
    headers: {
      'Authorization': use.token() ? `Bearer ${use.token()}` : ``,
      'Accept':'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    }
  }
}

const formQuery = args => {
  let keyValues = []
  Object.keys(args).forEach((arg, index) => {
    if (
      !['', undefined, null, []].includes(
        typeof args[arg] === 'string' ? args[arg].trim() : args[arg]
      )
    ) {
      keyValues.push(`${arg}=${args[arg]}`)
    }
  })
  return keyValues.length ? `?${keyValues.join('&')}` : ''
}

export const call = {
  item: {
    getItems: async function ({
      categoryId = '',
      tags = '',
      pageSize = 24,
      pageNumber = 1
    } = {}) {
      let response = await axios.get(
        `${Variables.base_url}/api/Item/GetItems${formQuery(arguments[0])}`
      )
      return response.data
    },
    getItemsByPriceIds: async ({ priceIds }) => {
      let response = await axios.get(
        `${Variables.base_url}/api/Item/GetItemsByPriceIds?priceIds=${priceIds}`
      )
      return response.data.data
    },
    getItem: async ({ id }) => {
      let response = await axios.get(
        `${Variables.base_url}/api/Item/GetItem?id=${id}`
      )
      return response.data.data
    }
  },
  category: {
    getCategories: async ({ parentId = '', type = '', enabled = '' }) => {
      let response = await axios.get(
        `${Variables.base_url}/api/Category/GetCategories?parentId=${parentId}&type=${type}&enabled=${enabled}`
      )
      return response.data.data
    },
    getCategory: async ({ id = '' }) => {
      let response = await axios.get(
        `${Variables.base_url}/api/Category/GetCategories?id=${id}`
      )
      return response.data.data[0]
    }
  },
  size: {
    getSizes: async parameters => {
      let response = await axios.get(
        `${Variables.base_url}/api/Size/GetSizes${formQuery(parameters)}`
      )
      return response.data.data
    },
    getSize: async id => {
      let response = await axios.get(
        `${Variables.base_url}/api/Size/GetSizes?id=${id}`
      )
      return response.data.data[0]
    }
  },
  substrate: {
    getSubstrates: async parameters => {
      let response = await axios.get(
        `${Variables.base_url}/api/Substrate/GetSubstrates${formQuery(
          parameters
        )}`
      )
      return response.data.data
    },
    getSubstrate: async id => {
      let response = await axios.get(
        `${Variables.base_url}/api/Substrate/GetSubstrates?id=${id}`
      )
      return response.data.data[0]
    }
  },
  country: {
    getCountryList: async () => {
      let response = await axios.get(
        `${Variables.base_url}/api/Country/GetCountries`
      )
      return response.data.data
    }
  },
  order: {
    createOrder: async order => {
      let response = await axios.post(
        `${Variables.base_url}/api/Order/CreateOrder`,
        order
      )
      return response.data
    }
  },
  user: {
    checkToken: async token => {
      let response = await axios
        .post(`${Variables.base_url}/api/User/CheckToken`, token, {
          headers: {
            'Content-Type': 'application/json;text/plain'
          }
        })
        .catch(err => {
          return null
        })
      return response?.data
    },
    login: async (email, password) => {
      let response = await axios
        .post(`${Variables.base_url}/api/User/Login`, {
          email: email,
          password: password
        })
        .catch(error => {
          return error.response
        })
      return response?.data
    },
    register: async user => {
      let response;
      await axios
        .post(`${Variables.base_url}/api/User/Register`, user)
        .then(result => response = result.data)
        .catch(error => response = error.response.data);
        
      return response
    },
    forgotPassword: async (user) => {
      let response = await axios
        .post(`${Variables.base_url}/api/User/ForgotPassword`, user)
        .catch(error => {
          return error.response
        })
      return response?.data
    },
    logout: async () => {
      let response = await axios
        .post(`${Variables.base_url}/api/User/Logout`, {}, header())
        .catch(err => {
          return null
        })
      return response
    },
    checkGuid: async(guid) =>{
      let response = await axios
      .get(`${Variables.base_url}/api/User/CheckGuid?guid=${guid}`)
      .catch(error => {
        return error.response
      })
      console.log(response);
      return response?.data
    },
    resetPassword: async (user)=>{
      let response = await axios
      .put(`${Variables.base_url}/api/User/ResetPassword`, user )
      .catch(error => {
        return error.response
      })
    return response?.data
    }
  },
  content: {
    getContents: async ({
      id = '',
      groupName = '',
      fullName = '',
      names = ''
    }) => {
      let response = await axios.get(
        `${Variables.base_url}/api/Content/GetContents?id=${id}&groupName=${groupName}&fullName=${fullName}&names=${names}`
      )
      return id || fullName ? response.data.data[0] : response.data.data
    }
  },
  clientRequest: {
    newRequest: async clientRequest => {
      let response = await axios.post(
        `${Variables.base_url}/api/ClientRequest/NewRequest`,
        clientRequest
      )
      return response.data
    },
    getClientRequestTypes: async () => {
      let response = await axios.get(
        `${Variables.base_url}/api/ClientRequestType/GetClientRequestTypes`
      )
      return response.data.data
    }
  },
  partner: {
    getPartners: async parameters => {
      let response = await axios.get(
        `${Variables.base_url}/api/Partner/GetPartners${formQuery(parameters)}`
      )
      return response.data.data
    }
  },
  //
  //For loggedUser, admin, & editor
  //

  forLoggedUser: {
    user: {
      getUsers: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/User/GetUsers${formQuery(parameters)}`,
          header()
        )
        return response.data
      },
      getUser: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/User/GetUser${formQuery(parameters)}`,
          header()
        )
        console.log('user', response.data)
        return response.data
      },
      addUser: async user => {
        let response = await axios.post(
          `${Variables.base_url}/api/User/AddUser`,
          user,
          header()
        )
        return response.data
      },
      updateUser: async user => {
        let response = await axios.put(
          `${Variables.base_url}/api/User/UpdateUser`,
          user,
          header()
        )
        return response.data
      },
      changePassword: async newPassword => {
        let response = await axios.put(
          `${Variables.base_url}/api/User/ChangePassword`,
          newPassword,
          header()
        )
        return response.data
      },
      getRoles: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/Role/GetRoles${formQuery(parameters)}`,
          header()
        )
        return response.data
      }
    },
    item: {
      getItems: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/Item/GetItems${formQuery(parameters)}`,
          header()
        )
        return response.data
      },
      getItem: async ({ id = '' }) => {
        let response = await axios.get(
          `${Variables.base_url}/api/Item/GetItem?id=${id}`,
          header()
        )
        return response.data.data
      },
      getPriceListByItemId: async ({ itemId }) => {
        let response = await axios.get(
          `${Variables.base_url}/api/Item/GetPriceListByItemId?itemId=${itemId}`,
          header()
        )
        return response.data
      },
      createItem: async item => {
        let response = await axios.post(
          `${Variables.base_url}/api/Item/CreateItem`,
          item,
          header()
        )
        return response.data.data
      },
      updateItem: async item => {
        let response = await axios.put(
          `${Variables.base_url}/api/Item/UpdateItem`,
          item,
          header()
        )
        return response.data.data
      }
    },
    size: {
      getSizes: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/Size/GetSizes${formQuery(parameters)}`,
          header()
        )
        return response.data
      },
      getSize: async id => {
        let response = await axios.get(
          `${Variables.base_url}/api/Size/GetSizes?id=${id}`,
          header()
        )
        return response.data.data[0]
      },
      createSize: async () => {},
      updateSize: async () => {}
    },
    substrate: {
      getSubstrates: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/Substrate/GetSubstrates${formQuery(
            parameters
          )}`,
          header()
        )
        return response.data
      },
      getSubstrate: async id => {
        let response = await axios.get(
          `${Variables.base_url}/api/Substrate/GetSubstrates?id=${id}`,
          header()
        )
        return response.data.data[0]
      },
      createSubstrate: async () => {},
      updateSubstrate: async () => {}
    },
    order: {
      getOrders: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/order/GetOrders${formQuery(parameters)}`,
          header()
        )

        return response.data
      },
      getOrder: async id => {
        let response = await axios.get(
          `${Variables.base_url}/api/Order/GetOrder?id=${id}`,
          header()
        )
        return response.data
      },
      createOrder: async order => {
        let response = await axios.post(
          `${Variables.base_url}/api/Order/CreateOrder`,
          order,
          header()
        )
        return response.data.data
      },
      updateOrder: async order => {
        let response = await axios.put(
          `${Variables.base_url}/api/Order/UpdateOrder`,
          order,
          header()
        )
        return response.data
      },
      updateOrderStatus: async order => {
        let response = await axios.put(
          `${Variables.base_url}/api/Order/UpdateOrderStatus`,
          order,
          header()
        )
        return response.data
      },
      createOrderPayment: async order => {
        let response = await axios.post(
          `${Variables.base_url}/api/CheckOut`,
          order,
          header()
        ).catch(err=>{
          return err;
        })
        console.log(response)
        return response
      }
    },
    orderItems: {
      getOrderItems: async orderId => {
        let response = await axios.get(
          `${Variables.base_url}/api/OrderItem/GetOrderItems?orderId=${orderId}`,
          header()
        )
        return response.data.data
      },
      createOrderItem: async () => {},
      updateOrderItem: async () => {}
    },
    discount:{
      getDiscounts: async parameters=>{
        let response = await axios.get(
          `${Variables.base_url}/api/Discount/GetDiscounts${formQuery(parameters)}`,
          header()
        )
        return response.data
      },
      getDiscount: async id => {
        let response = await axios.get(
          `${Variables.base_url}/api/Discount/GetDiscount?id=${id}`,
          header()
        )
        return response.data
      },
      getCouponInfo: async couponCode => {
        let response = await axios.get(
          `${Variables.base_url}/api/Discount/GetCouponInfo?couponCode=${couponCode}`,
          header()
        )
        return response.data.data
      },
      createDiscount: async discount => {
        let response = await axios.post(
          `${Variables.base_url}/api/Discount/CreateDiscount`,
          discount,
          header()
        )
        return response.data
      },
      updateDiscount: async discount => {
        let response = await axios.put(
          `${Variables.base_url}/api/Discount/UpdateDiscount`,
          discount,
          header()
        )
        return response.data
      }
    },
    category: {
      getCategories: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/Category/GetCategories${formQuery(
            parameters
          )}`,
          header()
        )
        return response.data
      },
      getCategory: async ({ id }) => {
        let response = await axios.get(
          `${Variables.base_url}/api/Category/GetCategory?id=${id}`,
          header()
        )
        return response.data
      },
      createCategory: async category => {
        let response = await axios.post(
          `${Variables.base_url}/api/Category/CreateCategory`,
          category,
          header()
        )
        return response.data
      },
      updateCategory: async category => {
        let response = await axios.put(
          `${Variables.base_url}/api/Category/UpdateCategory`,
          category,
          header()
        )
        return response.data.data
      }
    },
    country: {
      getCountryList: async () => {
        let response = await axios.get(
          `${Variables.base_url}/api/Country/GetCountries`,
          header()
        )
        return response.data
      },
      getCountry: async id => {
        let response = await axios.get(
          `${Variables.base_url}/api/Country/GetCountries?id=${id}`,
          header()
        )
        return response.data.data[0]
      }
    },
    dashboard: {
      getdashboardinfo: async () => {
        let response = await axios.get(
          `${Variables.base_url}/api/Dashboard/GetDashboardInfo`,
          header()
        )
        return response.data.data
      }
    },
    content: {
      getContents: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/Content/GetContents${formQuery(
            parameters
          )}`,
          header()
        )
        return response.data
      },
      updateContent: async content => {
        let response = await axios.put(
          `${Variables.base_url}/api/Content/UpdateContent`,
          content,
          header()
        )
        return response.data
      }
    },
    clientRequest: {
      getClientRequests: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/ClientRequest/GetClientRequests${formQuery(
            parameters
          )}`,
          header()
        )
        return response.data
      },
      getClientRequest: async id => {
        let response = await axios.get(
          `${Variables.base_url}/api/ClientRequest/GetClientRequest?id=${id}`,
          header()
        )
        return response.data.data
      },
      updateClientRequestStatus: async clientRequest => {
        let response = await axios.put(
          `${Variables.base_url}/api/ClientRequest/UpdateClientRequestStatus`,
          clientRequest,
          header()
        )
        return response.data.data
      },
      getClientRequestTypes: async () => {
        let response = await axios.get(
          `${Variables.base_url}/api/ClientRequestType/GetClientRequestTypes`,
          header()
        )
        return response.data.data
      },
      getClientRequestType: async id => {
        let response = await axios.get(
          `${Variables.base_url}/api/ClientRequestType/GetClientRequestTypes?id=${id}`,
          header()
        )
        return response.data.data
      },
      getClientRequestStatuses: async () => {
        let response = await axios.get(
          `${Variables.base_url}/api/ClientRequest/GetClientRequestStatuses`,
          header()
        )
        return response.data.data
      },
      getClientRequestStats: async () => {
        let response = await axios.get(
          `${Variables.base_url}/api/ClientRequest/GetClientRequestStats`,
          header()
        )
        return response.data.data
      }
    },
    partner: {
      getPartners: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/Partner/GetPartners${formQuery(
            parameters
          )}`,
          header()
        )
        return response.data
      },
      getPartner: async id => {
        let response = await axios.get(
          `${Variables.base_url}/api/Partner/GetPartner?id=${id}`,
          header()
        )
        return response.data.data
      },
      addPartner: async partner => {
        let response = await axios.post(
          `${Variables.base_url}/api/Partner/AddPartner`,
          partner,
          header()
        )
        return response.data.data
      },
      updatePartner: async partner => {
        let response = await axios.put(
          `${Variables.base_url}/api/Partner/UpdatePartner`,
          partner,
          header()
        )
        return response.data.data
      }
    },
    statuses: {
      getStatuses: async parameters => {
        let response = await axios.get(
          `${Variables.base_url}/api/Status/GetStatuses${formQuery(
            parameters
          )}`,
          header()
        )
        return response.data
      }
    },
    file: {
      getFile: async (path, fileName) => {
        let response = await axios.get(
          `${Variables.base_url}/api/GetUserFile/RequestAttachments/${path}`,
          {
            headers: {
              Authorization: `Bearer ${use.token()}`
            },
            responseType: 'blob'
          }
        )

        const href = window.URL.createObjectURL(response.data)

        const anchorElement = document.createElement('a')
        anchorElement.href = href
        anchorElement.download = fileName
        document.body.appendChild(anchorElement)
        anchorElement.click()
        document.body.removeChild(anchorElement)
        window.URL.revokeObjectURL(href)
        return response
      }
    }
  }
}
