import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Input from '../_Components/Input'
import { call } from '../../Methods'

function _DiscountEdit () {
  const { id } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [discount, setDiscount] = useState()

  const loadData = async() =>{
    setLoading(true)
    const _discount = await call.forLoggedUser.discount.getDiscount(id);
    if(_discount.success){
      setDiscount(_discount.data)
    }else{
      navigate('/admin/discounts',{replace:true})
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData();
  }, [])

  const saveDiscount = async enabled => {

    let _discount = {}
    _discount.id = +id
    _discount.code = getInputValueByName('code') 
    _discount.discountPercent = getInputValueByName('discountPercent') 
    _discount.activeTillDate = getInputValueByName('activeTillDate')
    _discount.statusId = getInputValueByName('statusId')
    setDiscount(_discount)
    //return;


    const _updatedDiscount = await call.forLoggedUser.discount.updateDiscount(_discount)
    
    if (_updatedDiscount.success) {
      setDiscount(_updatedDiscount.data)
      setLoading(false)
    }
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  return (
    !loading && (
      <React.Fragment>
        <form className='form'>
          <h4 className='form-title'>{discount.code}
          </h4>
          <div className='row'>
            <Input
              type={'text'}
              label={'Code'}
              fieldName={'code'}
              col={{ default: 12, md: 4 }}
              value={discount.code}
            />

            <Input
              type={'number'}
              label={'Percent'}
              fieldName={'discountPercent'}
              col={{ default: 12, sm: 12, md: 4 }}
              value={discount.discountPercent}
            />

            <Input
              type={'date'}
              label={'Date Created'}
              fieldName={'createDate'}
              col={{ default: 12, sm: 12, md: 4 }}
              value={discount.createDate?.substring(0,10)}
              disabled={false}
            />  

              <Input
              type={'date'}
              label={'Last Modified'}
              fieldName={'modifiedDate'}
              col={{ default: 12, sm: 12, md: 4 }}
              value={discount.modifiedDate?.substring(0,10)}
              disabled={false}
            />  

            <Input
              type={'date'}
              label={'Active Till'}
              fieldName={'activeTillDate'}
              col={{ default: 12, sm: 12, md: 4 }}
              value={discount.activeTillDate?.substring(0,10)}
            />  

            <Input
              type={'checkbox'}
              label={'Enabled'}
              fieldName={'statusId'}
              col={{ default: 12, sm: 12, md: 3 }}
              value={discount.statusId}
              checked={discount.statusId==1? true:false}
              eventHandler={{ 
                type:'change',
                handler: function(e){
                  e.target.checked? setDiscount({...discount, statusId:1}):setDiscount({...discount, statusId:0});
                }}}
            />
          </div>

          <div className='action-buttons'>
            <button
              type='button'
              className='back-button'
              onClick={function () {
                navigate('/admin/discounts', { replace: true })
              }}
            >
              Back
            </button>
            <button
              type='button'
              className='save-button'
              onClick={function () {
                saveDiscount(1)
              }}
            >
              Save
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  )
}

export default _DiscountEdit
