import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { call } from '../Methods.js'
import { updateItem, emptyCart } from '../Store/cartSlice.js'
import { use } from '../Helpers.js'
import ValidateInput from '../ValidationDefaults.js'
import WhyChooseUs from '../Components/WhyChooseUs.js'
import CheckOutItem from '../Components/CheckOutItem'

function CheckOut () {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userInfo = useSelector(state => state.user.userInfo)
  const cart = useSelector(state => state.cart)

  const [subTotalAmount, setSubTotalAmount] = useState(cart.totalAmount)
  const [hst, setHst] = useState(cart.hst)

  const [totalAmount, setTotalAmount] = useState(cart.hst + cart.totalAmount)

  const [discount, setDiscount] = useState(0)
  const [couponCode, setCouponCode] = useState(null)
  const [discountedPrice, setDiscountedPrice] = useState(0)
  const [discountPercent, setDiscountPercent] = useState(0)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [discountApplyLoading, setDiscountApplyLoading] = useState(false)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)

  const ValidateAs = {
    ordererfullname: ['required', 'fullName'],
    ordereremail: ['required', 'email'],
    orderercity: ['required'],
    orderercountry: ['required', 'id'],
    ordererphone: ['required'],
    ordereraddress: ['required'],
    ordererpostalcode: ['required']
  }


  const checkOut = async e => {
    setSending(true);
    let _orderDetails = {}
    _orderDetails.userId = userInfo?.id
    _orderDetails.discountId=discount.id
    _orderDetails.discountedPrice=discountedPrice
    let order_items_array = []

    cart.items.forEach((item, index) => {
      order_items_array.push({
        item_PriceId: item.priceId,
        singleItemPrice: item.price,
        quantity: item.count
      })
    })
    _orderDetails.orderItems = order_items_array
    _orderDetails.billingAddressIsSameWithShippingAddress =
      use.getInputValueByName('ask-billing')
    const payment = await call.forLoggedUser.order.createOrderPayment(
      _orderDetails
    )
    if (payment?.data.success) {
      console.log(payment.data.data.redirectUrl,payment)
      window.location.replace(payment.data.data.redirectUrl)
    }else if(payment?.status==403){
      alert("Check out operation is not allowed for this user. Description: 403")
    }
    setSending(false);
  }

  const loadData = async () => {
    setLoading(true)

    if (cart.items.length > 0) {
      let priceIds = cart.items.map(i => i.priceId)
      const _items = await call.item.getItemsByPriceIds({
        priceIds: priceIds.join(',')
      })

      _items.forEach(i => {
        dispatch(
          updateItem({
            priceId: i.price.id,
            active: i.price.enabled
          })
        )
      })
      setItems(_items)
    }
    setLoading(false)
  }

  const checkAndApplyDiscount = async () => {
    if(['',null,undefined].includes(couponCode)){
      setCouponCode(null);
      return false;
    }
    setDiscountApplyLoading(true)
    
    let response = await call.forLoggedUser.discount.getCouponInfo(couponCode)
    
    if(response.length>0){
      let _discount = response[0].discountPercent
      setDiscount(response[0]);
      setDiscountPercent(_discount)
      let discount_amount = use.normalizePrecision(
        (subTotalAmount * _discount) / 100
      )
      setDiscountAmount(discount_amount)

      let discounted_price = use.normalizePrecision(
        subTotalAmount - discount_amount
      )
      setDiscountedPrice(discounted_price)

      let hst_amount = use.normalizePrecision(
        discounted_price*13/100
      )
      
      setHst(hst_amount)

      let total_amount = use.normalizePrecision(+discounted_price + +hst_amount)
      setTotalAmount(total_amount)
    }else{
      setCouponCode(null);
      setDiscount(null)
    }
    setDiscountApplyLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    setSubTotalAmount(use.normalizePrecision(cart.totalAmount))
    setHst(use.normalizePrecision(cart.hst))
    setTotalAmount(
      use.normalizePrecision(cart.hst) +
      use.normalizePrecision(cart.totalAmount)
    )
  }, [cart])

  if (loading) return <div className='loading'>Loading...</div>
  if (!items) return <div className='loading'>Something wrong happen...</div>
  return (
    <React.Fragment>
      <div className='page check-out-page'>
        <div className='page-content'>
          <div className='page-header'>
            <h1 className='main'>Shopping Cart</h1>
            <p className='sub'>
              This is your card based on what you want to buy
            </p>
          </div>
          {items && (
            <div className='row'>
              <div
                className='col-12 col-sm-12 col-md-12 col-lg-8 check-out-items'
                style={{ padding: '0 15px' }}
              >
                {cart.items.length > 0 && items.length > 0 ? (
                  items.map((item, index) => {
                    return <CheckOutItem key={index} _item={item} />
                  })
                ) : (
                  <>
                    <div>No items added to cart</div>
                    <div>
                      Go to <Link to='/items'>Items</Link> page to add items.
                    </div>
                  </>
                )}
              </div>
              <div
                className='col-12 col-sm-12 col-md-12 col-lg-4'
                style={{ padding: '0 15px' }}
              >
                {
                  <div className='order-form'>
                    {sending && <div className='sending'>Please Wait...</div>}
                    <p className='order-checkout-header'>Order Summary</p>
                    <hr />
                    <p className='sub-total conclusion'>
                      <span>Order Subtotal:</span>
                      <span>$ {loading ? 0 : subTotalAmount}</span>
                    </p>
                    <p>
                      <div className='discount input-box disabled'>
                        <input
                          onChange={e => setCouponCode(e.target.value?e.target.value:null)}
                          type='text'
                          placeholder='Coupon code'
                          disabled={discountAmount>0?true:false}
                        />
                        <button
                          type='button'
                          disabled={discountAmount>0?true:false}
                          onClick={e => {
                            checkAndApplyDiscount(e)
                          }}
                        >
                          {discountApplyLoading ? 'Checking...' : discountAmount>0?'Applied':'Apply'}
                        </button>
                      </div>
                    </p>
                    {discountAmount > 0 && discountPercent && (
                      <div>
                        <p className='discount-rate conclusion'>
                          <span>Discount:</span>
                          <span>
                            {discountApplyLoading ? 0 : discountPercent} %
                          </span>
                        </p>
                        <p className='sub-total conclusion'>
                          <span>Subtotal:</span>
                          <span>$ {loading ? 0 : discountedPrice}</span>
                        </p>
                      </div>
                    )}

                    <hr />
                    <p className='hst conclusion'>
                      <span className=''>HST:</span>
                      <span>$ {loading ? 0 : +use.normalizePrecision(hst)}</span>
                    </p>
                    <p className='grand-total conclusion'>
                      <span>Order Total:</span>
                      <span>$ {loading ? 0 : use.normalizePrecision(totalAmount)}</span>
                    </p>
                    {cart.items.length > 0 && (
                      <p className='ask-billing'>
                        <input
                          id='ask-billing'
                          type='checkbox'
                          name='ask-billing'
                        />
                        <label htmlFor='ask-billing'>
                          Billing address is same with the shipping address
                        </label>
                      </p>
                    )}
                    <div className='stripe-button row'>
                        <div className='button-box col-12 col-sm-12'>
                          <button
                            className='button button-full-width'
                            onClick={e =>
                              userInfo
                                ? checkOut(e)
                                : navigate('/login', {
                                    state: { url: '/checkout' }
                                  })
                            }
                            disabled={cart.items.length == 0}
                          >
                            {!userInfo && 'Sign in to '}Checkout
                          </button>
                        </div>
                        <img
                          className='supported-payments col-12 col-sm-12'
                          src='/Images/stripe.png'
                        />
                      </div>
                  </div>
                }
              </div>
            </div>
          )}
        </div>

        <WhyChooseUs />
      </div>
    </React.Fragment>
  )
}

export default CheckOut
