import React, { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function CustomerReviews () {
  const [reviews, setReviews] = useState([
    { id: 1, stars: 5, reviewHeader: "Excellent Quality and Service", reviewContent: "I've ordered safety vests and hard hats with custom logos from The Pro Prints for our construction site, and they’ve been outstanding. The visibility of the high-vis vests is great, and they fit well. The hard hats look fantastic, and the logo printing on everything is sharp and clear. Fast shipping, good pricing, and excellent quality. Very satisfied with my order!", customerName: 'David M. – Vancouver, BC' },
    {
      id: 2,
      stars: 5,
      reviewHeader: 'Amazing Custom Workwear!',
      reviewContent: `I ordered custom hoodies and t-shirts for my team at work, and we couldn't be happier with the results! The quality of the fabric is excellent, and the printing is sharp and professional. The customer service was also top-notch—they helped us with the design and made sure everything was perfect before production. Highly recommend The Pro Prints for any custom apparel needs!`,
      customerName: 'John D. – Toronto, ON'
    },
    {
      id: 3,
      stars: 5,
      reviewHeader: 'Fast and Reliable Service',
      reviewContent:
        '"I was in a rush to get some customized parking signs for our building, and The Pro Prints delivered quickly! They were very responsive to my questions and worked with me to get the design just right. The signs arrived on time and look fantastic! I will definitely be using them again for any future needs."',
      customerName: 'Maria L. – Mississauga, ON'
    },
    { id: 4, stars: 5, reviewHeader: "Top-notch Signs for Our Business", reviewContent: '"We ordered customized signs for our office building, and The Pro Prints exceeded our expectations! The signs are durable, weather-resistant, and look great. They really helped us with the design, and the colors came out perfectly. The process was smooth from start to finish, and the customer service was amazing. We’ll be back for sure!"', customerName: 'Linda P. – Calgary, AB' },
    { id: 5, stars: 5, reviewHeader: "Great Customization Options!", reviewContent: "I needed some custom bags and apparel for an upcoming conference, and The Pro Prints had exactly what I was looking for. The selection of colors, sizes, and designs was impressive, and the custom printing turned out beautifully. The team was also really helpful in guiding me through the process. I was impressed with how easy they made everything!"
, customerName: 'Michael R. – Ottawa, ON' },
    { id: 6, stars: 5, reviewHeader: "Great Quality, Fast Delivery!", reviewContent: '"I ordered custom workwear for my team, and I was really impressed with the quality and how quickly everything arrived. The material is comfortable and durable, and the designs were exactly what we wanted. I’ll definitely be ordering again in the future. Thank you, The Pro Prints!"', customerName: 'Samantha W. – Toronto, ON' },
    { id: 7, stars: 5, reviewHeader: '"Perfect Custom Signs for Our Facility"', reviewContent: "The Pro Prints helped us design and print custom safety signs for our manufacturing facility. They took the time to understand our needs and provided great suggestions. The signs are clear, durable, and highly visible. I’m very happy with the quality, and we will definitely be working with them again for any future signage needs.", customerName: 'Jason K. – Hamilton, ON' }
  ])

  var settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 1,
    dots: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 8000,
    lazyLoad: true,
    cssEase: 'linear',
    slidesToScroll: 1,
    appendDots: dots => (
      <div
        style={{
          borderRadius: '10px',
          padding: '10px',
          bottom: '0px'
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className='customer-reviews page-section'>
        <div className='section-header'>
          <h2 className='main'>Reviews by customers</h2>
          <p className='sub'>What our customers think about us?</p>
        </div>
        <section className='slider'>
          {reviews && (
            <Slider {...settings}>
              {reviews.map((review, index) => (
                <div key={index} className='container'>
                  <div class='slider-item customer-review' key={index}>
                    <div className="content">
                    <h4 className='review-header'>{review.reviewHeader}</h4>
                    <p>{review.reviewContent}</p>
                    </div>
                    
                    <div className='stars'>{'★'.repeat(review.stars)}</div>
                    <p className='author'>- {review.customerName} -</p>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </section>
      </div>
    </React.Fragment>
  )
}

export default CustomerReviews
