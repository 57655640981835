import React, { useEffect, useRef, useState } from 'react'
import {
  Link,
  useParams,
  useLocation,
  useSearchParams,
  useNavigation
} from 'react-router-dom'
import ItemCard from '../Components/ItemCard'
import Sidebar from '../Components/Sidebar.js'
import { call } from '../Methods.js'
import ItemsList from '../Components/ItemsList.js'

function Items () {
  const [searchQueryParameters, setSearchQueryParameters] = useSearchParams()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchMode, setSearchMode] = useState(false)
  const [showCategoriesMenu, setShowCategoriesMenu] = useState(false)
  const pageSize = 24
  const [responseInfo, setResponseInfo] = useState()
  const [sortRule, setSortRule] = useState('From Lowest Price')
  const sortSelectRef = useRef()

  function toggleCategoriesMenu () {
    setShowCategoriesMenu(!showCategoriesMenu)
  }

  useEffect(()=>{
    setItems([])
  },[searchQueryParameters.get('tags')])

  const loadItems = async (
    {
      category,
      tags,
      pageSize = 24,
      pageNumber = responseInfo?.pageNumber || 1
    },
    empty = false
  ) => {
    setLoading(true)

    const _items = await call.item.getItems({
      categoryId: category > 0 ? category : '',
      tags,
      pageSize,
      pageNumber
    })

    setResponseInfo(
      (({ success, pageCount, length, pageSize, pageNumber }) => {
        return {
          success: success,
          pageCount: pageCount,
          length: length,
          pageSize: pageSize || 24,
          pageNumber: pageNumber || 1
        }
      })(_items)
    )

    if (pageNumber == 1) {
      setItems([..._items.data])
      window.scrollTo(0, 0)
    } else {
      setItems([...items, ..._items.data])
    }
    setLoading(false)
  }

  const loadMore = async () => {
    let prm = {}

    let category = searchQueryParameters.get('category'),
      tags = searchQueryParameters.get('tags'),
      pageNumber = responseInfo.pageNumber + 1

    if (category) prm.category = category
    if (tags) prm.tags = tags
    if (pageNumber) prm.pageNumber = pageNumber

    setSearchQueryParameters(prm)

    setResponseInfo({
      ...responseInfo,
      pageNumber: responseInfo.pageNumber + 1
    })
  }

  const searchItemsByTags = async e => {
    setResponseInfo({
      ...responseInfo,
      pageNumber: 1
    })
    if (e.key === 'Enter') {
      let searchInput = document.getElementById('search-input')
      let inputValue = searchInput.value.trim()
      if (document.activeElement === searchInput && inputValue !== '') {
        setSearchMode(true)
        setSearchQueryParameters({ tags: inputValue, pageNumber: 1 })
        let button = document.getElementById('search-button')
        button.focus()
      }
    }
  }

  const clearSearchInput = async () => {
    setSearchMode(false)
    setSearchQueryParameters({})
    document.getElementById('search-input').value = ''
  }

  useEffect(() => {
    let parameters = Object.fromEntries(searchQueryParameters.entries())

    loadItems(parameters)
  }, [searchQueryParameters])

  const toggleShowOptions = e => {
    sortSelectRef.current.classList.toggle('open')
  }

  useEffect(() => {
    sortSelectRef.current.classList.remove('open')
  }, [sortRule])

  return (
    <>
      <div className='page items-page'>
      {
            <Sidebar
              selected={searchQueryParameters}
              show={showCategoriesMenu}
              clickHandler={toggleCategoriesMenu}
            />
          }
          <div className='items-list'>
            <div className='load-more'>
              <div
                className='sort-by'
                ref={sortSelectRef}
                onClick={toggleShowOptions}
              >
                <div className='selected-option' value={sortRule}>
                  Sort by&nbsp;<b>{sortRule}</b>{' '}
                  <span className='chevron-icon'></span>
                </div>
                <div className='options'>
                  <div
                    onClick={() => {
                      setSortRule('Default')
                    }}
                  >
                    Default
                  </div>
                  <div
                    onClick={() => {
                      setSortRule('Newest')
                    }}
                  >
                    Newest
                  </div>
                  <div
                    onClick={() => {
                      setSortRule('Oldest')
                    }}
                  >
                    Oldest
                  </div>
                  <div
                    onClick={() => {
                      setSortRule('Price: Low to High')
                    }}
                  >
                    Price: Low to High
                  </div>
                  <div onClick={() => setSortRule('Price: High to Low')}>
                    Price: High to Low
                  </div>
                </div>
              </div>
              {responseInfo && (
                <>
                  <div className='result-info'>
                    RESULTS: SHOWING 1-
                    {responseInfo.length > pageSize * +responseInfo.pageNumber
                      ? pageSize * +responseInfo.pageNumber
                      : responseInfo.length}{' '}
                    OF {responseInfo.length} PRODUCTS
                  </div>
                </>
              )}
            </div>
            {items && items.length > 0 && (
              <>
                <ItemsList items={items} />
                <div className='load-more'>
                  {responseInfo && (
                    <>
                      <button
                        id='load-more-button'
                        className={
                          responseInfo.pageCount === responseInfo.pageNumber
                            ? 'disabled'
                            : ''
                        }
                        data-nextpage={responseInfo.pageNumber + 1}
                        onClick={e =>
                          responseInfo.pageCount === responseInfo.pageNumber
                            ? false
                            : loadMore(e)
                        }
                      >
                        {loading ? 'Loading...' : `Load More`}
                      </button>
                      <div className='result-info'>
                        RESULTS: SHOWING 1-
                        {responseInfo.length >
                        pageSize * +responseInfo.pageNumber
                          ? pageSize * +responseInfo.pageNumber
                          : responseInfo.length}{' '}
                        OF {responseInfo.length} PRODUCTS
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {!loading && items.length == 0 && <span>No items</span>}
            {loading && <span>Loading...</span>}
            <div className='banner'>
              <img src='/Images/banner.jpg' />
            </div>
          </div>
      </div>
    </>
  )
}

export default Items