import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Table from '../_Components/AdminTable.js'
import { useTable } from 'react-table'
import Variables from '../../global.js'
import { call } from '../../Methods.js'

function _Discounts () {
  const [tableData, setTableData] = useState([])

  const defaultParameters = {
    parentId: '',
    type: '',
    enabled: '',
    statusId: ''
  }

  let tableColumns = [
    {
      title: 'Code',
      property: 'code'
    },
    {
      title: 'Percent',
      property: 'discountPercent'
    },
    {
      title: 'Create Date',
      property: 'createDate',
      mutate: value => {
        return value?.substring(0,10)
      }
    },
    {
      title: 'Active Till',
      property: 'activeTillDate',
      mutate: value => {
        return value?.substring(0,10)
      }
    },
    {
      title: 'Active',
      property: 'statusId',
      type: 'label',
      mutate: value => {
        return value == 0 ? (
          <span className='label-red'>deactive</span>
        ) : (
          <span className='label-green'>active</span>
        )
      }
    },
    {
      title: 'Actions',
      property: 'id',
      type: 'actions-list',
      mutate: value => {
        return (
          <>
            <Link to={`/admin/discount/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  const getData = async () => {
    const _discounts = await call.forLoggedUser.discount.getDiscounts({});
    if(_discounts.success){
      setTableData(_discounts)
    }else{
      alert('Exception')
    }
    return _discounts
  }

  useEffect(() => {
    getData()
  }, [])


  return (
    <React.Fragment>
      <h3 className='page-title'>Discounts</h3>
      { tableData && tableData.data &&
        <Table
            columns={tableColumns}
            data={tableData.data}
            loading={tableData?false:true}
            pagination={{ enable: false }}
            addNew={{ enabled: true, url: '/admin/discount/add' }}
        />
      }

    </React.Fragment>
  )
}

export default _Discounts
