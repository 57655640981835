import React, { Fragment, useEffect, useRef, useState } from 'react'
import {  useLocation, useNavigate, Link, useParams } from 'react-router-dom'
import { use } from '../Helpers'
import { call } from '../Methods'
import Input from '../Admin/_Components/Input'
import ValidateInput from '../ValidationDefaults'
import NotFound from './NotFound'


export default function ResetPassword () {
  let navigate = useNavigate()
  const {state} = useLocation();
  const {guid} = useParams();
  const [result,setResult] = useState(null);
  const [loading,setLoading] = useState(false);

  useEffect(()=>{
    checkGuid();
  },[])

  const checkGuid = async () =>{
    const _result = await call.user.checkGuid(guid);
    if(_result.success){
      console.log(_result.data)
      if(!_result.data){
        setResult(false)
      }
    }
  } 

  const ValidateAs = {
    newPassword: ['required', 'password'],
    reenteredPassword: ['required', 'password']
  }

  async function Reset (e) {
    setLoading(true);
    e.preventDefault();

    let newPassword = document.querySelector('.reset-password-form input[name="new-password"]').value
    let reenteredPassword = document.querySelector('.reset-password-form input[name="reentered-password"]').value

    let validationResults = [];
    [...e.target.elements].forEach(input => {
      if (ValidateAs[input.name]) {
        let errorSpan = document.querySelector(
          `span.warning-message[for=${input.name}]`
        )

        let result = ValidateInput(
          input.name,
          ValidateAs[input.name],
          input.value
        )
        validationResults.push(result)

        if (!result.result) {
          errorSpan.classList.add('show')
          errorSpan.innerText = result?.message
        }
      }
    })

    let validationResult = validationResults.every(r => r.result === true)

    if (validationResult) {
      let user = {};
      user.confirmationToken = guid;
      user.newPassword = newPassword;
      user.reenteredPassword = reenteredPassword;

      const _result = await call.user.resetPassword(user);
      if (_result?.success) {
        alert('Your password is changed.')
        navigate(`/login`);
      }else{
        alert(_result.error.message)
      }
    }
    setLoading(false);
  }


  if(result === false) return <NotFound/>
  return (
      <div className="page account-page">
        <img className="svg-background" src="/Backgrounds/wave-haikei.svg"/>
        <div className="account-forms">
        <div className="reset-password-form form-container">
        <h1>
          Reset Password
        </h1>
        <form
          className='form'
          noValidate
          method='post'
          onSubmit={e => {
            Reset(e)
          }}
        >
          <Input
            label={'New Password'}
            fieldName={'new-password'}
            type={'password'}
            disabled={loading}
            validateAs={'password'}
          />
          <Input
            label={'Confirm New Password'}
            fieldName={'reentered-password'}
            type={'password'}
            disabled={loading}
            validateAs={'password'}
          />
          <div className='button-box'>
            <button
              className='button  button-full-width'
              disabled={loading}
              type='submit'
            >
              {loading ? 'Please,wait...' : 'Reset'}
            </button>
          </div>
        </form>
        </div>
        </div>
      </div>

    )
}
