import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Variables from '../global'
import { use } from '../Helpers'
import { updateItem, removeItem } from '../Store/cartSlice'
import { Link } from 'react-router-dom'
import Vector from '../Vector'

export default function CheckOutItem ({ _item }) {
  const dispatch = useDispatch()
  const item = useSelector(state =>
    state.cart.items.find(i => i.priceId == _item.price.id)
  )

  const _price = _item.price

  function increaseItemCount () {
    if (_price.enabled == 0) {
      return false
    }
    let itemCount = item.count + 1
    dispatch(updateItem({ priceId: item.priceId, count: itemCount }))
  }

  function decreaseItemCount () {
    if (_price.enabled == 0) {
      return false
    }
    let itemCount = item.count - 1
    if (itemCount === 0) {
      let x = window.confirm('Are you sure you want to remove the item from the card?')
      if (x) {
        dispatch(removeItem({ priceId: item.priceId }))
      }
      return
    }
    dispatch(updateItem({ priceId: item.priceId, count: itemCount }))
  }

  function _removeItem () {
    let x = window.confirm('Are you sure to remove item from cart?')
    if (x) {
      dispatch(removeItem({ priceId: item.priceId }))
    }
  }

  return (
    <React.Fragment>
      {item && (
        <div
          className={`check-out-item${_price.enabled == 0 ? ' disabled' : ''}`}
        >
          {_price.enabled == 0 && (
            <span className='price-is-not-active'>
              Price is changed. Please, add the item with current price.
            </span>
          )}
          <div className='row main-content'>
            <div className='col-4' style={{alignSelf:'center'}}>
              <img
                src={`${Variables.base_url}/api/images/thumbnail-md/${_item.image}`}
              />
            </div>
            <div className='col-8' style={{ padding: '10px' }}>
              <div className='item-name'>
                <Link title={_item.itemName} to={`/item/${_item.id}`}>{_item.itemName}</Link>
                <span className='remove-item'>
                    <button onClick={_removeItem}>&times;</button>
                </span>
              </div>
              <div className='item-stars'>
                {Array.from({ length: 5 }, (_, index) => (
                  <Vector
                    key={index}
                    style={{ margin: '0 2px', width: '9px' }}
                    icon={'star'}
                    fill={'#3c3c3c'}
                  />
                ))}
              </div>
              <div className='item-details'>
                <div className='item-parameters'>
                  {_price.sizeName && (
                    <div className='item-parameter'>
                      <span className='parameter-name'>Size:</span>{' '}
                      {_price.sizeName}
                    </div>
                  )}
                  {_price.substrateName && (
                    <div className='item-parameter'>
                      <span className='parameter-name'>Substrate:</span>{' '}
                      {_price.substrateName}
                    </div>
                  )}
                </div>
                <span className='item-count'>
                  <button onClick={decreaseItemCount}>-</button>{' '}
                  <span className='count'><span className="text">Count: </span> {item.count}</span>
                  <button onClick={increaseItemCount}>+</button>
                </span>
              </div>
              <div className='item-price'>
                  $ {use.precisionRound(_price.price * item.count, 2)}
                </div>
                
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
