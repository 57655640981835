import React, { useEffect, useState } from 'react'
import Table from '../_Components/AdminTable'
import axios from 'axios'
import Variables from '../../global'
import { Pagination } from 'react-bootstrap'
import { call } from '../../Methods'
import { Link, useSearchParams } from 'react-router-dom'
import { use } from '../../Helpers'

function _Orders () {
  const [tableData,setTableData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const [userParameters,setUserParameters] = useState(use.userSettings().searchSettings);

  const getData = async searchParameters => {
    console.log(use.statusColors())
    const _orders = await call.forLoggedUser.order.getOrders({})
    if(_orders.success){
      setTableData(_orders);
    }
    return _orders
  }

  let tableColumns = [
    {
      title: 'Number',
      property: 'orderNumber'
    },
    {
      title: 'User',
      property: ['userEmail','userId'],
      mutate: values => {
        return <Link className="link" to={`/admin/user/${values[1]}`}>{values[0]}</Link>
      }
    },
    {
      title: 'Date',
      property: 'createDate',
      mutate:value=>{
        let _values = value.split('T');
        return `${_values[0]} ${_values[1].substring(0,5)}`;
      }
    },
    {
      title: 'Items',
      property: 'itemCount'
    },
    {
      title: 'Subtotal',
      property: 'subTotal'
    },
    {
      title: 'HST',
      property: 'hst'
    },
    {
      title: 'Discount',
      property: 'discountCode',
      type: 'label',
      mutate: value => {
        return (
          value? <span style={{ backgroundColor: 'green' }}>
            {'%'}
          </span>:null
        )
      }
    },
    {
      title: 'Status',
      property: ['statusId','statusName'],
      type: 'label',
      mutate: values => {
        return (
          <span style={{ backgroundColor: use.statusColors().forOrders[values[0]] }}>
            {values[1]}
          </span>
        )
      }
    },
    {
      title: 'Actions',
      property: 'id',
      type: 'actions-list',
      mutate: value => {
        return (
          <>
            <Link to={`/admin/order/${value}`}>
              <img
                className='action-icon'
                title={'Edit'}
                src={'/Icons/edit.png'}
              />
            </Link>
          </>
        )
      }
    }
  ]

  useEffect(()=>{
    getData(use.userSettings());
  },[])

  return (
    <React.Fragment>
      <h3 className='page-title'>Orders</h3>
        {tableData && tableData.data && <Table
          columns={tableColumns}
          loading={tableData.data? false:true}
          data={tableData?.data}
          addNew={{ enabled: true, url: '/admin/order/add' }}
          pagination={{
            enabled: true,
            enable:true,
            settings:{
              pageNumber: +searchParams.get("pageNumber") || 1,
              pageSize: +searchParams.get("pageSize") || 10,
              sort: searchParams.get("sort") || 'asc',
              length: tableData.length || 0,
              pageCount: tableData.pageCount || 0
            }
          }}
        />}
    </React.Fragment>
  )
}

export default _Orders
