import React from 'react'

function WhyChooseUs ({}) {
  return (
    <React.Fragment>
      <div className='why-choose-us page-section'>
        <div className="container">
          <div className="section-header">
            <h2 className="main">Why Choose Us?</h2>
          </div>
        <div className='row'>
          <div className='col-12 col-sm-6 col-md-3'>
            <div className='reason-card'>
              <div className='icon'>
                <img src='./Icons/package.svg' />
              </div>
              <h5>Free shipping</h5>
              <p>
                Shop to Your Heart's Content Without Worrying About Shipping
                Costs: Our Free Shipping Service Delivers Your Purchases with a
                Smile, Straight to Your Doorstep!
              </p>
            </div>
          </div>
          <div className='col-12 col-sm-6 col-md-3'>
            <div className='reason-card'>
              <div className='icon'>
                <img src='./Icons/phone.svg' />
              </div>
              <h5>24/7 Support</h5>
              <p>
                Shop with Confidence Anytime, Anywhere: Our Free Shipping
                Service Comes with 24/7 Support to Ensure Your Packages Arrive
                Safely and On Time!
              </p>
            </div>
          </div>
          <div className='col-12 col-sm-6 col-md-3'>
            <div className='reason-card'>
              <div className='icon'>
                <img src='./Icons/email.svg' />
              </div>
              <h5>Daily email</h5>
              <p>
                Stay Up-to-Date with Your Deliveries: Enjoy the Convenience of
                Daily Email Updates with Our Free Shipping Service, Making Your
                Online Shopping Experience Even More Enjoyable!
              </p>
            </div>
          </div>
          <div className='col-12 col-sm-6 col-md-3'>
            <div className='reason-card'>
              <div className='icon'>
                <img src='./Icons/credit-card.svg' />
              </div>
              <h5>EASY PAYMENT</h5>
              <p>
                Shop and Pay with Ease: Our Free Shipping Service Not Only
                Delivers Your Packages for Free, but Also Offers Easy Payment
                Options, Making Your Shopping Experience a Breeze!
              </p>
            </div>
          </div>
        </div>
        </div>
        
      </div>
    </React.Fragment>
  )
}

export default WhyChooseUs
