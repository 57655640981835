import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Cart from './Cart'
import { useDispatch, useSelector } from 'react-redux'
import { call } from '../Methods'
import Test from './Test'

function Header () {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userShort = useSelector(state => state.user.userInfo)
  const mobileView = useSelector(state => state.viewSettings.isMobile)
  const [loading, setLoading] = useState(true)
  const [socialMediaContent, setSocialMediaContent] = useState(null)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const loadData = async () => {
    setLoading(true)
    const _socialMediaContent = await call.content.getContents({
      groupName: 'SocialMedia'
    })
    setSocialMediaContent(_socialMediaContent)
    setLoading(false)
  }

  useEffect(() => {
    setShowMobileMenu(false)
  }, [mobileView])

  useEffect(() => {
    loadData()
  }, [])

  const scrollHandler = e => {
    let header = document.getElementsByTagName('header')[0]
    console.log(header)
    let logo = document.querySelector('img#webpage_logo')
    function onScroll () {
      if (window.scrollY > 180) {
        header?.classList.add('dark')
        logo.src = '/Logos/original2.png'
      } else {
        header?.classList.remove('dark')
        logo.src = '/Logos/original.png'
      }
    }
    if (header != null && logo != null) {
      document.addEventListener('scroll', onScroll)
    } else {
      document.removeEventListener('scroll', onScroll)
    }
  }

  useEffect(() => {
    scrollHandler()
  }, [])

  const toggleLoggedClientMenu = (e, close = false) => {
    if(e.target.classList.contains('open')){
      setTimeout(()=>{e.target.classList.remove('open')},100)
    }else{
      e.target.classList.add('open')
    }
  }

  const toggleShowMobileMenu = () => {
    document.body.style.overflow = !showMobileMenu ? 'hidden' : 'scroll'
    setShowMobileMenu(showMobileMenu ? false : true)
  }

  const selectMenuItem = e => {
    let headerLinks = document.querySelectorAll('ul.nav-menu li.header-link a')
    headerLinks.forEach(l => l.classList.remove('selected'))
    e.target.classList.add('selected')
    setShowMobileMenu(false)
  }

  const searchItemByTags = tags =>{
    navigate(`/items?tags=${tags}`, { replace: true });
  }



  return (
    <>
      <header
        className={`header${
          location.pathname === '/' ? ' home-page-header' : ''
        }`}
      >
        <div className='logo'>
          <Link to='/'>
            <img
              id='webpage_logo'
              src='/Logos/original.png'
              alt='theproprints logo'
            />
          </Link>
        </div>
        <div className='search-container'>
          <div className='search-bar'>
          <svg
            className='search-icon'
            xmlns='http://www.w3.org/2000/svg'
            id='Outline'
            viewBox='0 0 24 24'
            width='512'
            height='512'
          >
            <path d='M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z' />
          </svg>
          <input id='search-box' type='text' name='search' placeholder="Search something" onKeyUp={e => ['Enter','NumpadEnter'].includes(e.code)?searchItemByTags(e.target.value):null}/>
          </div>
        </div>
        <nav className={`header-menu`}>
          <ul
            className='nav-menu'
            style={mobileView ? { display: 'none' } : {}}
          >
            <li className='header-link'>
              <Link to='/' onClick={e => selectMenuItem(e)}>
                Home
              </Link>
            </li>
            <li className='header-link'>
              <Link to='/items' onClick={e => selectMenuItem(e)}>
                Products
              </Link>
            </li>
            <li className='header-link'>
              <Link to='https://www.healthandsafetyadvisors.ca/' target='_blank'>
                H&S Advisors
              </Link>
            </li>
            <li className='header-link'>
              <Link to='/contact' onClick={e => selectMenuItem(e)}>
                Contact us
              </Link>
            </li>
          </ul>
          <div className='icons'>
            <ul className='icon-menu'>
              <li className='header-link'>
                <Link to='/checkout' onClick={e => selectMenuItem(e)}>
                  <Cart />
                </Link>
              </li>
              <li className='header-link'>
                {!userShort && (
                  <Link to='/login' onClick={e => selectMenuItem(e)}>
                    <div className='account'>
                    <img className="account-icon" src="./Icons/profile.svg"/>
                    </div>
                  </Link>
                )}
                {userShort && (
                  <div
                    tabIndex={1}
                    className='account-info'
                    onFocus={e => toggleLoggedClientMenu(e)}
                    onBlur={e => toggleLoggedClientMenu(e)}
                  >
                    {userShort.firstName[0]?.toUpperCase()}
                    {userShort.lastName[0]?.toUpperCase()}
                    <div className='user-menu'>
                      <ul>
                        <li className="user-name">
                            {userShort.firstName} {userShort.lastName}
                        </li>
                        {userShort.roleId === 1 && (
                          <>
                            <li>
                              <Link to={`/dashboard/${userShort.userName}`}>
                                Profile
                              </Link>
                            </li>
                          </>
                        )}
                        {userShort.roleId === 2 && (
                          <>
                            <li>
                              <Link to={`/admin/dashboard`}>Dashboard</Link>
                            </li>
                          </>
                        )}
                        <li>
                          <Link to='/logout'>Sign out</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </li>
              <li
                className='header-link'
                style={mobileView ? { display: 'block' } : { display: 'none' }}
              >
                <div
                  className={`hamburger-menu${showMobileMenu ? ' open' : ''}`}
                  onClick={toggleShowMobileMenu}
                >
                  <div className='icon'>
                    <div className='line'></div>
                    <div className='line'></div>
                    <div className='line'></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <div
          className={`mobile-menu-container${showMobileMenu ? ' open' : ''}`}
        >
          <nav className='mobile-menu'>
            <ul>
              <li>
                <Link to={'/'} onClick={() => toggleShowMobileMenu()}>
                  Home
                </Link>
              </li>
              <li>
                <Link to={'/items'} onClick={() => toggleShowMobileMenu()}>
                  Products
                </Link>
              </li>
              <li className='header-link'>
              <Link to='https://www.healthandsafetyadvisors.ca/' target='_blank'>
                H&S Advisors
              </Link>
            </li>
              <li>
                <Link to={'/contact'} onClick={() => toggleShowMobileMenu()}>
                  Contact Us
                </Link>
              </li>
              <li>
                <div className='socials-container'>
                  <label>We are on Social Media</label>
                  <ul className='nav-social'>
                    <li>
                      <a href='https://facebook.com' target='_blank'>
                        <img src='/Icons/socialnetworks/facebook.png' />
                      </a>
                    </li>
                    <li>
                      <a href='https://x.com' target='_blank'>
                        <img src='/Icons/socialnetworks/x.png' />
                      </a>
                    </li>
                    <li>
                      <a href='https://instagram.com' target='_blank'>
                        <img src='/Icons/socialnetworks/instagram.png' />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  )
}
export default Header
