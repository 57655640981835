import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Input from '../_Components/Input'
import { call } from '../../Methods'

function _DiscountAdd () {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const formRef = useRef()

  const [discount, setDiscount] = useState({
    id: undefined,
    code: undefined,
    discountPercent:undefined,
    createDate: undefined,
    activeTillDate: undefined,
    modifiedDate:undefined,
    statusId: undefined
  })

  const saveDiscount = async () => {
    setLoading(true)


    let _discount = {}
    _discount.discountPercent = getInputValueByName('discountPercent')
    _discount.code = getInputValueByName('code')
    _discount.activeTillDate = getInputValueByName('activeTillDate')
    _discount.statusId = getInputValueByName('statusId')
    setDiscount(_discount)

    //return;
    const _newDiscount = await call.forLoggedUser.discount.createDiscount(
      _discount
    )

    if (_newDiscount.success) {
      formRef.current.reset()
      setDiscount(_newDiscount.data)
      navigate(`/admin/discount/${_newDiscount.data.id}`, { replace: true })
    }
    setLoading(false)
  }

  const getInputValueByName = name => {
    let element = document.querySelectorAll(`form [name="${name}"]`)
    if (element.length === 1) {
      return element[0].value || element[0].text
    } else if (element.length > 1) {
      let valuesArray = []
      element.forEach(el => valuesArray.push(el.value))
      return valuesArray
    }
    return null
  }

  useEffect(() => {
    
  }, [])

  return (
    <React.Fragment>
      <form className='form' ref={formRef}>
        <h4 className='form-title'>{'Add New Discount'}</h4>
        {loading && <div className='loader'>Loading...</div>}
        <div className='row'>
        <Input
              type={'text'}
              label={'Code'}
              fieldName={'code'}
              col={{ default: 12, md: 4 }}
              value={null}
            />

            <Input
              type={'number'}
              label={'Percent'}
              fieldName={'discountPercent'}
              col={{ default: 12, sm: 12, md: 4 }}
              value={null}
            />

            <Input
              type={'date'}
              label={'Active Till'}
              fieldName={'activeTillDate'}
              col={{ default: 12, sm: 12, md: 4 }}
              value={null}
            />  

            <Input
              type={'checkbox'}
              label={'Enabled'}
              fieldName={'enabled'}
              col={{ default: 12, sm: 12, md: 3 }}
              value={0}
              eventHandler={{ 
                type:'change',
                handler: function(e){
                  e.target.checked? setDiscount({...discount, statusId:1}):setDiscount({...discount, statusId:0});
                }}}
            />
        </div>

        <div className='action-buttons'>
          <button
            type='button'
            className='back-button'
            onClick={function () {
              navigate('/admin/discounts', { replace: true })
            }}
          >
            Back
          </button>
          <button
            type='button'
            className='save-button'
            onClick={function () {
              saveDiscount(1)
            }}
          >
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default _DiscountAdd
